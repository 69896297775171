import { DateTime } from 'luxon'

export default {
  methods: {
    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''

      for (let i = 0; i < array.length; i++) {
        let line = ''
        for (const index in array[i]) {
          if (line != '') line += ','

          line += array[i][index]
        }

        str += `${line}\r\n`
      }

      return str
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers)
      }

      // Convert Object to JSON
      const jsonObject = JSON.stringify(items)

      const csv = this.convertToCSV(jsonObject)

      const exportedFilenmae = `${fileTitle}.csv` || 'export.csv'

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportedFilenmae)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    toCamelCase(str) {
      let newStr = ''
      if (str) {
        const wordArr = str.split(/[-_]/g)
        for (const i in wordArr) {
          if (i > 0) {
            newStr += wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1)
          } else {
            newStr += wordArr[i]
          }
        }
      } else {
        return newStr
      }
      return newStr
    },
    humanise(str) {
      if (str !== null) {
        let i; const
          frags = str.split('_')
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
        }
        return frags.join(' ')
      }

      return 'NA'
    },
    transformDate(dateString, useTimeAgo = false, format = 'DDD') {
      const units = [
        'year',
        'month',
        'week',
        'day',
        'hour',
        'minute',
        'second',
      ]

      const timeAgo = date => {
        const dateTime = DateTime.fromISO(date)
        const diff = dateTime.diffNow().shiftTo(...units)
        const newUnit = units.find(unit => diff.get(unit) !== 0) || 'second'

        const relativeFormatter = new Intl.RelativeTimeFormat('en', {
          numeric: 'auto',
        })
        return relativeFormatter.format(Math.trunc(diff.as(newUnit)), newUnit)
      }

      const defaultTime = date => {
        const dateTime = DateTime.fromISO(date)
        return dateTime.toFormat(format)
      }

      if (useTimeAgo) {
        return timeAgo(dateString)
      }
      return defaultTime(dateString)
    },
  },
}
